// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.adds-sticky {
  position: fixed;
  max-height: 100px;
  bottom: 0;
  z-index: 99999;
  width: 100%;
  color: "red";
  -webkit-backdrop-filter: blur(6px);
          backdrop-filter: blur(6px);
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .adds-sticky {
    display: none;
  }
}

.sticky {
  min-width: 250px;
  max-width: 1200px;
  width: 100%;
  min-height: 50px;
  max-height: 100px;
  height: 100%;
}

.BoxAdd {
  display: flex;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .BoxAdd {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/component/adds/adds.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,iBAAA;EACA,SAAA;EACA,cAAA;EACA,WAAA;EACA,YAAA;EACA,kCAAA;UAAA,0BAAA;AACJ;AAAI;EARJ;IASK,aAAA;EAGH;AACF;;AAAA;EACI,gBAAA;EACF,iBAAA;EACA,WAAA;EAEA,gBAAA;EACA,iBAAA;EACA,YAAA;AAEF;;AACA;EACE,aAAA;AAEF;AADE;EAFF;IAGI,aAAA;EAIF;AACF","sourcesContent":[".adds-sticky{\n    position: fixed;\n    max-height: 100px;\n    bottom: 0;\n    z-index: 99999;\n    width: 100%;\n    color:\"red\";\n    backdrop-filter: blur(6px);\n    @media screen and (min-width: 320px) and (max-width: 768px) {\n     display: none;\n    }\n}\n\n.sticky{\n    min-width: 250px;\n  max-width: 1200px;\n  width: 100%;\n \n  min-height: 50px;\n  max-height: 100px;\n  height: 100%\n}\n\n.BoxAdd{\n  display: flex;\n  @media screen and (min-width: 320px) and (max-width: 768px) {\n    display: none;\n   }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
