// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Homescreen-box {
  min-height: 100vh;
  background-color: rgba(244, 244, 244, 0.471);
}

.Homescreen-txt1 {
  font-family: "NotoSansMalayalam-Bold";
  padding-bottom: 5px;
  margin-bottom: 5px;
  font-size: 25px;
}

.Homescreen-loading {
  height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "NotoSansMalayalam-Regular";
  font-size: 14px;
  color: gray;
}

.Homescreen-item {
  margin-bottom: 20px;
  border: 1px solid rgb(193, 199, 203);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.Homescreen-itemBox {
  padding: 15px;
}

.Homescreen-itemImage {
  width: 100%;
  aspect-ratio: 1/1.4;
}

.Homescreen-itemBox2 {
  padding: 10px;
  text-align: center;
  border-top: 1px solid rgb(5, 118, 183);
  background-color: rgb(5, 118, 183);
  font-family: "NotoSansMalayalam-SemiBold";
  color: #fff;
}

.Homescreen-itemImg {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/screens/homescreen/styles.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,4CAAA;AACF;;AAEA;EACE,qCAAA;EACA,mBAAA;EACA,kBAAA;EACA,eAAA;AACF;;AAEA;EACE,YAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,wCAAA;EACA,eAAA;EACA,WAAA;AACF;;AAEA;EACE,mBAAA;EACA,oCAAA;EACA,0CAAA;AACF;;AAEA;EACE,aAAA;AACF;;AACA;EACE,WAAA;EACA,mBAAA;AAEF;;AACA;EACE,aAAA;EACA,kBAAA;EACA,sCAAA;EACA,kCAAA;EACA,yCAAA;EACA,WAAA;AAEF;;AACA;EACE,WAAA;AAEF","sourcesContent":[".Homescreen-box {\n  min-height: 100vh;\n  background-color: rgba(244, 244, 244, 0.471);\n}\n\n.Homescreen-txt1 {\n  font-family: \"NotoSansMalayalam-Bold\";\n  padding-bottom: 5px;\n  margin-bottom: 5px;\n  font-size: 25px;\n}\n\n.Homescreen-loading {\n  height: 70vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-family: \"NotoSansMalayalam-Regular\";\n  font-size: 14px;\n  color: gray;\n}\n\n.Homescreen-item {\n  margin-bottom: 20px;\n  border: 1px solid rgb(193, 199, 203);\n  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);\n}\n\n.Homescreen-itemBox {\n  padding: 15px;\n}\n.Homescreen-itemImage {\n  width: 100%;\n  aspect-ratio: 1/1.4;\n}\n\n.Homescreen-itemBox2 {\n  padding: 10px;\n  text-align: center;\n  border-top: 1px solid rgb(5, 118, 183);\n  background-color: rgb(5, 118, 183);\n  font-family: \"NotoSansMalayalam-SemiBold\";\n  color: #fff;\n}\n\n.Homescreen-itemImg {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
