// import moment from "moment";

// const CropImage = async (ratio: any, imageRef: any, urls: any) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       if (imageRef) {
//         imageRef.crossOrigin = "anonymous";
//         imageRef.onload = async function (owner: any) {
//           const canvas = document.createElement("canvas");
//           const scaleX = imageRef.naturalWidth / imageRef.width;
//           const scaleY = imageRef.naturalHeight / imageRef.height;
//           const scaleFactor = 10;
//           canvas.width = ratio.width! * scaleFactor;
//           canvas.height = ratio.height! * scaleFactor;
//           const ctx = canvas.getContext("2d")!;
//           ctx.drawImage(
//             imageRef,
//             ratio.x! * scaleX,
//             ratio.y! * scaleY,
//             ratio.width! * scaleX,
//             ratio.height! * scaleY,
//             0,
//             0,
//             ratio.width! * scaleFactor,
//             ratio.height! * scaleFactor
//           );
//           const dataUrl = canvas.toDataURL("image/png", 1.0);
//           const blob = dataURLtoBlob(dataUrl);
//           let name = moment(new Date()).unix();
//           let url = new File([blob], name + "-crop.png", { type: "image/png" });
//           resolve(url);
//           imageRef.crossOrigin = "";
//         };
//         imageRef.src = urls;
//         imageRef.onerror = function () {
//           imageRef.crossOrigin = "";
//           console.log("image load faild");
//         };
//       } else {
//         resolve("error");
//       }
//     } catch (err) {
//       console.log("err", err);
//       resolve("error");
//     }
//   });
// };

// const dataURLtoBlob = (dataURL: string) => {
//   const arr = dataURL.split(",");
//   const mime = arr[0].match(/:(.*?);/)![1];
//   const bstr = atob(arr[1]);
//   let n = bstr.length;
//   const u8arr = new Uint8Array(n);
//   while (n--) {
//     u8arr[n] = bstr.charCodeAt(n);
//   }
//   return new Blob([u8arr], { type: mime });
// };

// export default CropImage;

import moment from "moment";
import logo from "../../../asset/images/logo.png";
import gulfLogo from "../../../asset/images/gulf-logo.png";

const CropImage = async (
  ratio: any,
  imageRef: any,
  urls: any,
  edition: any
) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (imageRef) {
        imageRef.crossOrigin = "anonymous";
        imageRef.onload = async function (owner: any) {
          const canvas = document.createElement("canvas");
          const scaleX = imageRef.naturalWidth / imageRef.width;
          const scaleY = imageRef.naturalHeight / imageRef.height;
          const scaleFactor = 10;
          canvas.width = ratio.width! * scaleFactor;
          canvas.height = ratio.height! * scaleFactor;
          const ctx = canvas.getContext("2d")!;

          // Draw the main image
          ctx.drawImage(
            imageRef,
            ratio.x! * scaleX,
            ratio.y! * scaleY,
            ratio.width! * scaleX,
            ratio.height! * scaleY,
            0,
            0,
            ratio.width! * scaleFactor,
            ratio.height! * scaleFactor
          );

          try {
            const watermarkImg = new Image();
            watermarkImg.crossOrigin = "anonymous";

            await new Promise((resolve, reject) => {
              watermarkImg.onload = () => {
                // Reduced watermark size
                const watermarkWidth = canvas.width * 0.5; // 50% of cropped image width
                const watermarkHeight = canvas.height * 0.2; // Adjust between 0.2 and 0.3

                // Reduced opacity for better readability
                ctx.globalAlpha = 0.15; // Reduced from 0.7 to 0.15

                // Position watermark at bottom right instead of center
                const x = (canvas.width - watermarkWidth) / 2; // Center horizontally
                const y = (canvas.height - watermarkHeight) / 2; // Center vertically

                ctx.drawImage(
                  watermarkImg,
                  x,
                  y,
                  watermarkWidth,
                  watermarkHeight
                );
                ctx.globalAlpha = 1.0;
                resolve(true);
              };

              watermarkImg.onerror = () => {
                console.log("Watermark failed to load");
                resolve(false);
              };

              watermarkImg.src = edition === "Gulf" ? gulfLogo : logo; // Your watermark path
            });
          } catch (watermarkError) {
            console.log("Error applying watermark:", watermarkError);
          }

          const dataUrl = canvas.toDataURL("image/png", 1.0);
          const blob = dataURLtoBlob(dataUrl);
          let name = moment(new Date()).unix();
          let url = new File([blob], name + "-crop.png", { type: "image/png" });
          resolve(url);
          imageRef.crossOrigin = "";
        };
        imageRef.src = urls;
        imageRef.onerror = function () {
          imageRef.crossOrigin = "";
          console.log("image load failed");
        };
      } else {
        resolve("error");
      }
    } catch (err) {
      console.log("err", err);
      resolve("error");
    }
  });
};

const dataURLtoBlob = (dataURL: string) => {
  const arr = dataURL.split(",");
  const mime = arr[0].match(/:(.*?);/)![1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};

export default CropImage;
