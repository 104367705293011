// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Footer {
  background-color: #000;
  color: #fff;
  padding: 10px;
  font-size: 14px;
  text-align: center;
  margin-top: 50px;
}
`, "",{"version":3,"sources":["webpack://./src/component/footer/footer.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,WAAW;EACX,aAAa;EACb,eAAe;EACf,kBAAkB;EAClB,gBAAgB;AAClB","sourcesContent":[".Footer {\n  background-color: #000;\n  color: #fff;\n  padding: 10px;\n  font-size: 14px;\n  text-align: center;\n  margin-top: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
