// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./asset/fonts/NotoSansMalayalam-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./asset/fonts/NotoSansMalayalam-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./asset/fonts/NotoSansMalayalam-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./asset/fonts/NotoSansMalayalam-SemiBold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./asset/fonts/NotoSansMalayalam_Condensed-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("./asset/fonts/NotoSansMalayalam-Light.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("./asset/fonts/NotoSansMalayalam-ExtraBold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "NotoSansMalayalam-Bold";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");
}

@font-face {
  font-family: "NotoSansMalayalam-Regular";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("truetype");
}

@font-face {
  font-family: "NotoSansMalayalam-Medium";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("truetype");
}

@font-face {
  font-family: "NotoSansMalayalam-SemiBold";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("truetype");
}

@font-face {
  font-family: "NotoSansMalayalam-Condensed-Regular";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_4___})
    format("truetype");
}

@font-face {
  font-family: "NotoSansMalayalam-Light";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format("truetype");
}

@font-face {
  font-family: "NotoSansMalayalam-ExtraBold";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_6___}) format("truetype");
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,kBAAkB;EAClB;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE,qCAAqC;EACrC,+DAAuE;AACzE;;AAEA;EACE,wCAAwC;EACxC,+DAA0E;AAC5E;;AAEA;EACE,uCAAuC;EACvC,+DAAyE;AAC3E;;AAEA;EACE,yCAAyC;EACzC,+DAA2E;AAC7E;;AAEA;EACE,kDAAkD;EAClD;sBACoB;AACtB;;AAEA;EACE,sCAAsC;EACtC,+DAAwE;AAC1E;;AAEA;EACE,0CAA0C;EAC1C,+DAA4E;AAC9E","sourcesContent":["body {\n  margin: 0;\n  padding: 0;\n  overflow-x: hidden;\n  font-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\",\n    \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\",\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\n@font-face {\n  font-family: \"NotoSansMalayalam-Bold\";\n  src: url(\"./asset/fonts/NotoSansMalayalam-Bold.ttf\") format(\"truetype\");\n}\n\n@font-face {\n  font-family: \"NotoSansMalayalam-Regular\";\n  src: url(\"./asset/fonts/NotoSansMalayalam-Regular.ttf\") format(\"truetype\");\n}\n\n@font-face {\n  font-family: \"NotoSansMalayalam-Medium\";\n  src: url(\"./asset/fonts/NotoSansMalayalam-Medium.ttf\") format(\"truetype\");\n}\n\n@font-face {\n  font-family: \"NotoSansMalayalam-SemiBold\";\n  src: url(\"./asset/fonts/NotoSansMalayalam-SemiBold.ttf\") format(\"truetype\");\n}\n\n@font-face {\n  font-family: \"NotoSansMalayalam-Condensed-Regular\";\n  src: url(\"./asset/fonts/NotoSansMalayalam_Condensed-Regular.ttf\")\n    format(\"truetype\");\n}\n\n@font-face {\n  font-family: \"NotoSansMalayalam-Light\";\n  src: url(\"./asset/fonts/NotoSansMalayalam-Light.ttf\") format(\"truetype\");\n}\n\n@font-face {\n  font-family: \"NotoSansMalayalam-ExtraBold\";\n  src: url(\"./asset/fonts/NotoSansMalayalam-ExtraBold.ttf\") format(\"truetype\");\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
